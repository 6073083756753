import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Offcanvas } from "react-bootstrap";
import Anchor from "../elements/Anchor";

const MobileInfo = ({
  showMobileInfo,
  setShowMobileInfo,
  navbarItems,
  stores,
}) => {
  return (
    <Offcanvas
      show={showMobileInfo}
      onHide={() => setShowMobileInfo(false)}
      placement="end"
      className="bg-primary text-white"
    >
      <Offcanvas.Body>
        <div className="mobile-info">
          <div className="d-flex flex-1 justify-content-end">
            <button
              className="mobile-info-close-btn p-2"
              type="button"
              onClick={() => setShowMobileInfo(false)}
            >
              x
            </button>
          </div>

          <div className="d-flex flex-column">
            <Anchor
              href={`tel:+30${navbarItems.phone_number}`}
              className="d-flex align-items-center mb-3"
            >
              <StaticImage
                src="../../images/icons/telephone-white.png"
                alt="phone"
                className="me-2"
              />
              <strong>{navbarItems.phone_number}</strong>
            </Anchor>
            <Anchor
              href={`tel:+30${navbarItems.mobile_number}`}
              className="d-flex align-items-center mb-3"
            >
              <StaticImage
                src="../../images/icons/mobile-white.png"
                alt="mobile"
                className="me-2"
              />
              <strong>{navbarItems.mobile_number}</strong>
            </Anchor>
            <Anchor
              href={`mailto:${navbarItems.e_mail}`}
              className="d-flex align-items-center mb-3"
            >
              <StaticImage
                src="../../images/icons/email-white.png"
                alt="e-mail"
                className="me-2"
              />
              <strong>{navbarItems.e_mail}</strong>
            </Anchor>
          </div>

          <div className="mb-5">
            <Anchor
              href={navbarItems.facebook_link.url}
              target={navbarItems.facebook_link.target}
              className="me-4"
            >
              <StaticImage
                src="../../images/icons/facebook-white.png"
                alt="facebook"
              />
            </Anchor>
            <Anchor
              href={navbarItems.instagram_link.url}
              target={navbarItems.instagram_link.target}
              className="me-4"
            >
              <StaticImage
                src="../../images/icons/instagram-white.png"
                alt="instagram"
              />
            </Anchor>
            <Anchor
              href={navbarItems.youtube_link.url}
              target={navbarItems.youtube_link.target}
              className="me-4"
            >
              <StaticImage
                src="../../images/icons/youtube-white.png"
                alt="youtube"
              />
            </Anchor>
          </div>
          {stores.map((store) => {
            return (
              <div key={store.store_title} className="mb-5">
                <StaticImage
                  src="../../images/icons/location-mobile.png"
                  alt="location icon"
                  className="mb-2"
                />
                <h2>{store.store_title}</h2>
                <h4>{store.store_address}</h4>
                <Anchor
                  href={`tel:+30${store.telephone}`}
                  className="d-block mb-2"
                >
                  Τηλ: {store.telephone}
                </Anchor>
                <p className="mb-2">Fax: {store.fax}</p>
                <Anchor href={`mailto:${store.email}`} className="mb-2">
                  Email: {store.email}
                </Anchor>
              </div>
            );
          })}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MobileInfo;
