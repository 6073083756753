/*
 * Resolve a target URL relative to a base URL
 *
 * @param {string} from - The Base URL being resolved against
 * @param {string} to - The HREF URL being resolved
 * @returns {string} A target URL relative to a base URL.
 */
export const urlResolve = (from, to) => {
  const resolvedUrl = new URL(to, new URL(from, "resolve://"));
  if (resolvedUrl.protocol === "resolve:") {
    const { pathname, search, hash } = resolvedUrl;
    return pathname + search + hash;
  }
  return resolvedUrl.toString();
};

/*
 * Truncate a string until a certain limit
 *
 * @param {string} text - The text to be truncated
 * @param {number} limit - Number
 * @returns {string} A new string containing the specified part of the given string.
 */
export const truncate = (text, limit) => text.substring(0, limit);

/*
 * Get the level of the heading from a prismic richtext field
 *
 * @param {object} title - An object that contains the property richText with a property type
 * @returns {string} A new string containing the heading level.
 */
export const headingLevel = (title) => title.richText[0].type?.slice(-1);

/*
 * Construct data for the active document, used for multilanguage
 *
 * @param {object} doc - The document data object returned from prismic query
 * @returns {object} The activeDocMeta object.
 */
export const constructActiveDoc = (activeDoc) => {
  const { lang, type, url } = activeDoc;
  const alternateLanguages = activeDoc.alternate_languages || [];

  return {
    lang,
    type,
    url,
    alternateLanguages,
  };
};

/*
 * Check if an email is valid
 *
 * @param {string} email - The email address to check
 * @returns {boolean} True/false for the validity of the given email
 */
export const isValidEmail = (email) =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );

/*
 * Check if a number is a valid Greek phone
 *
 * The number must NOT have any whitespace and can have an optional country
 * prefix +30 or 0030.
 *
 * @param {string} number - The phone number to check
 * @returns {boolean} True/false for the validity of the given input
 */
export const isValidPhone = (phone) => /^(\+30|0030)?\d{10}$/.test(phone);
