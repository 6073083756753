import React, { useState, useContext, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "./Menu";
import MobileInfo from "./MobileInfo";
import Anchor from "../elements/Anchor";
import MenuContext from "../../context/MenuContext";

const Header = ({ settings, showSticky }) => {
  const { isMenuOpen, setIsMenuOpen, setStores } = useContext(MenuContext);
  const navbarItems = settings.data.navbar[0];
  const menuItems = settings.data.body;
  const [showMobileInfo, setShowMobileInfo] = useState(false);
  useEffect(() => {
    setStores(settings.data.stores);
  }, []);

  return (
    <Container fluid className="header-container px-0 pe-xl-3 py-3 ">
      {/* MOBILE */}
      <div className="mobile-header d-flex d-xl-none justify-content-between align-items-end bg-white py-2">
        <button
          type="button"
          onClick={() => setIsMenuOpen(true)}
          className="mobile-menu-btn"
        >
          <StaticImage src="../../images/menu-btn.svg" alt="Menu button" />
        </button>
        <Menu
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          menuItems={menuItems}
        />
        <Anchor href="/" className="mobile-logo">
          <StaticImage src="../../images/logo-sticky.png" alt="Logo" />
        </Anchor>
        <button type="button" onClick={() => setShowMobileInfo(true)}>
          <StaticImage
            src="../../images/icons/info-btn.png"
            alt="show info button"
          />
        </button>
        <MobileInfo
          navbarItems={navbarItems}
          stores={settings.data.stores}
          showMobileInfo={showMobileInfo}
          setShowMobileInfo={setShowMobileInfo}
        />
      </div>

      {/* DESKTOP */}
      <div className="d-none d-xl-block">
        {/* DESKTOP NORMAL HEADER */}
        <Row className="header">
          <Col sm={5} className="d-flex align-items-end">
            <button
              type="button"
              onClick={() => setIsMenuOpen(true)}
              className="mobile-menu-btn"
            >
              <StaticImage src="../../images/menu-btn.svg" alt="Menu button" />
            </button>
          </Col>
          <Col
            sm={2}
            className="d-flex justify-content-center align-items-center"
          >
            <Anchor href="/" className="header-logo">
              <StaticImage src="../../images/logo.png" alt="Menu button" />
            </Anchor>
          </Col>
          <Col
            sm={5}
            className="d-flex align-items-end justify-content-end pb-2"
          >
            <div className="d-flex">
              <div className="d-flex flex-column">
                <Anchor
                  href={`tel:+30${navbarItems.phone_number}`}
                  className="d-flex align-items-center mb-2"
                >
                  <StaticImage
                    src="../../images/icons/phone.png"
                    alt="phone"
                    className="me-2"
                  />
                  <strong>{navbarItems.phone_number}</strong>
                </Anchor>
                <Anchor
                  href={`tel:+30${navbarItems.mobile_number}`}
                  className="d-flex align-items-center"
                >
                  <StaticImage
                    src="../../images/icons/mobile.png"
                    alt="mobile"
                    className="me-2"
                  />
                  <strong>{navbarItems.mobile_number}</strong>
                </Anchor>
              </div>
              <div className="d-flex flex-column ms-4">
                <Anchor
                  href={`mailto:${navbarItems.e_mail}`}
                  className="d-flex align-items-center mb-2"
                >
                  <StaticImage
                    src="../../images/icons/email.png"
                    alt="e-mail"
                    className="me-2"
                  />
                  <strong>{navbarItems.e_mail}</strong>
                </Anchor>
                <a href="/#about-stores" className="d-flex align-items-center">
                  <StaticImage
                    src="../../images/icons/location.png"
                    alt="locations"
                    className="me-2"
                  />
                  <strong>{navbarItems.stores_label}</strong>
                </a>
              </div>
            </div>

            <div className="ms-3 mb-3">
              <Anchor
                href={navbarItems.facebook_link.url}
                target={navbarItems.facebook_link.target}
                className="ms-4"
              >
                <StaticImage
                  src="../../images/icons/facebook.png"
                  alt="facebook"
                />
              </Anchor>
              <Anchor
                href={navbarItems.instagram_link.url}
                target={navbarItems.instagram_link.target}
                className="ms-4"
              >
                <StaticImage
                  src="../../images/icons/instagram.png"
                  alt="instagram"
                />
              </Anchor>
              <Anchor
                href={navbarItems.youtube_link.url}
                target={navbarItems.youtube_link.target}
                className="ms-4"
              >
                <StaticImage
                  src="../../images/icons/youtube.png"
                  alt="youtube"
                />
              </Anchor>
            </div>
          </Col>
        </Row>
        {/* DESKTOP STICKY HEADER */}
        {showSticky && (
          <div className="sticky-header d-flex bg-white">
            <button
              type="button"
              onClick={() => setIsMenuOpen(true)}
              className="sticky-menu-btn me-3"
            >
              <StaticImage src="../../images/menu-btn.svg" alt="Menu button" />
            </button>
            <Anchor
              href="/"
              className="d-flex justify-content-center align-items-center me-auto ms-4"
            >
              <StaticImage
                src="../../images/logo-sticky.png"
                alt="sticky logo"
              />
            </Anchor>
            <div className="d-flex align-items-center justify-content-end">
              <div className="d-flex">
                <Anchor
                  href={`tel:+30${navbarItems.phone_number}`}
                  className="d-flex align-items-center me-4"
                >
                  <StaticImage
                    src="../../images/icons/phone.png"
                    alt="phone"
                    className="me-2"
                  />
                  <strong>{navbarItems.phone_number}</strong>
                </Anchor>
                <Anchor
                  href={`tel:+30${navbarItems.mobile_number}`}
                  className="d-flex align-items-center me-4"
                >
                  <StaticImage
                    src="../../images/icons/mobile.png"
                    alt="mobile"
                    className="me-2"
                  />
                  <strong>{navbarItems.mobile_number}</strong>
                </Anchor>
                <Anchor
                  href={`mailto:${navbarItems.e_mail}`}
                  className="d-flex align-items-center me-4"
                >
                  <StaticImage
                    src="../../images/icons/email.png"
                    alt="e-mail"
                    className="me-2"
                  />
                  <strong>{navbarItems.e_mail}</strong>
                </Anchor>
                <a
                  href="/#about-stores"
                  className="d-flex align-items-center me-4"
                >
                  <StaticImage
                    src="../../images/icons/location.png"
                    alt="locations"
                    className="me-2"
                  />
                  <strong>{navbarItems.stores_label}</strong>
                </a>
              </div>

              <div className="ms-3">
                <Anchor
                  href={navbarItems.facebook_link.url}
                  target={navbarItems.facebook_link.target}
                  className="ms-4"
                >
                  <StaticImage
                    src="../../images/icons/facebook.png"
                    alt="facebook"
                  />
                </Anchor>
                <Anchor
                  href={navbarItems.instagram_link.url}
                  target={navbarItems.instagram_link.target}
                  className="ms-4"
                >
                  <StaticImage
                    src="../../images/icons/instagram.png"
                    alt="instagram"
                  />
                </Anchor>
                <Anchor
                  href={navbarItems.youtube_link.url}
                  target={navbarItems.youtube_link.target}
                  className="ms-4"
                >
                  <StaticImage
                    src="../../images/icons/youtube.png"
                    alt="youtube"
                  />
                </Anchor>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Header;
