/* eslint-disable react/no-array-index-key */
import React from "react";
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";

const Footer = ({ settings }) => {
  const footer1 = settings.data.footer_menu;
  const footer2 = settings.data.footer_menu_2;
  const footer3 = settings.data.footer_menu_3;
  if (!settings) return null;

  return (
    <footer className="mt-4 mb-md-5">
      <Container className="bg-light d-flex flex-column flex-md-row justify-content-md-between mt-4 py-5 px-sm-5 px-xl-2 px-xxl-4 br20">
        <div className="d-flex flex-column flex-xl-row justify-content-xl-between flex-grow-1">
          <div className="d-none d-xl-block">
            <GatsbyImage
              image={settings.data.logo.gatsbyImageData}
              alt={settings.data.logo.alt || "logo"}
              className="my-5 my-xl-0"
            />
          </div>
          <div className="d-flex flex-column mb-4">
            {footer1.map((item, i) => {
              if (item.link.url) {
                return (
                  <span key={i}>
                    <Anchor
                      className="mb-2 d-block"
                      href={item.link.url}
                      target={item.link.target}
                    >
                      <u>{item.label}</u>
                    </Anchor>
                  </span>
                );
              }
            })}
          </div>
          <div
            className="d-flex flex-column mb-4"
            style={{ maxWidth: "210px" }}
          >
            {footer2.map((item, i) => {
              if (item.link.url) {
                return (
                  <span key={i}>
                    <Anchor
                      className="mb-2 d-block"
                      href={item.link.url}
                      target={item.link.target}
                    >
                      <u>{item.label}</u>
                    </Anchor>
                  </span>
                );
              }
            })}
          </div>
          <div className="d-flex flex-column mb-4">
            {footer3.map((item, i) => {
              if (item.link.url) {
                return (
                  <span key={i}>
                    <Anchor
                      className="mb-2 d-block"
                      href={item.link.url}
                      target={item.link.target}
                    >
                      <u>{item.label}</u>
                    </Anchor>
                  </span>
                );
              }
            })}
          </div>
        </div>

        <div className="ms-xl-5">
          <div className="d-xl-none">
            <GatsbyImage
              image={settings.data.logo.gatsbyImageData}
              alt={settings.data.logo.alt || "logo"}
              className="my-4 mt-md-0"
            />
          </div>
          <div style={{ maxWidth: "285px" }}>
            <div className="d-flex flex-column pb-3 border-bottom border-dark">
              <span>
                Copyright 2015-2021 Nasco Γενική Ανακαινιστική Μ. Ε.Π.Ε
              </span>
              <span>
                H Nasco είναι μέλος του Τεχνικού Επιμελητηρίου Ελλάδας
              </span>
            </div>
            <div className="d-flex flex-column pt-3">
              <span>© Copyright 2022 Nas.Co</span>
              <span>All Rights Reserved</span>
              <Anchor href="https://think-plus.gr/" target="_blank">
                Developed by<strong> Think Plus</strong>
              </Anchor>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
