import React, { useContext } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Offcanvas } from "react-bootstrap";
import Anchor from "../elements/Anchor";
import MenuContext from "../../context/MenuContext";

const MenuProducts = ({
  activeCategoryProducts,
  activeCategoryData,
  placement,
}) => {
  const {
    isProductCategoryListOpen,
    isProductCategoryOpen,
    setIsProductCategoryOpen,
    closeMenu,
  } = useContext(MenuContext);

  return (
    <Offcanvas
      show={isProductCategoryOpen}
      onHide={() => setIsProductCategoryOpen(false)}
      placement={placement}
      // backdrop="false"
      className={`menu-container placement-${placement}`}
    >
      <Offcanvas.Body>
        <div className="menu-product-list d-flex flex-column justify-content-between">
          <div
            className={`d-flex flex-1 d-xxl-none justify-content-${
              isProductCategoryListOpen ? "between" : "end"
            }`}
          >
            {isProductCategoryListOpen && (
              <button
                className="p-4"
                type="button"
                onClick={() => setIsProductCategoryOpen(false)}
              >
                <StaticImage
                  src="../../images/icons/back-btn.png"
                  alt="back button"
                />
              </button>
            )}
            <button className="p-4" type="button" onClick={() => closeMenu()}>
              <StaticImage
                src="../../images/icons/close-btn.png"
                alt="close button"
              />
            </button>
          </div>
          <button
            className="close-category-btn d-none d-xxl-block p-4"
            type="button"
            onClick={() => setIsProductCategoryOpen(false)}
          >
            <StaticImage
              src="../../images/icons/back-btn.png"
              alt="close products button"
            />
          </button>
          <div className="d-flex flex-column flex-grow-1 justify-content-between flex-xxl-row">
            <div className="d-flex flex-column align-items-start justify-content-xxl-center px-5 py-4">
              {activeCategoryData && (
                <h2 className="mb-4 text-primary">
                  {activeCategoryData.primary.label}
                </h2>
              )}
              <div>{activeCategoryProducts}</div>
            </div>
            {activeCategoryData && (
              <div className="category-image-wrapper">
                <GatsbyImage
                  image={activeCategoryData.primary.image.gatsbyImageData}
                  alt={activeCategoryData.primary.image.alt}
                  className="category-image"
                />
                {/* <Anchor
                  href={
                    `/posts?ideas=${activeCategoryData.primary.post_idea_tag}` ||
                    "/"
                  }
                >
                  <button
                    type="button"
                    className="d-flex align-items-center justify-content-between p-2 bg-white"
                    onClick={() => closeMenu()}
                  >
                    <h5 className="w-50 mb-0 ms-4">ΠΡΟΤΑΣΕΙΣ & ΙΔΕΕΣ</h5>
                    <StaticImage
                      src="../../images/icons/redRightArrow.png"
                      alt="ΠΡΟΤΑΣΕΙΣ & ΙΔΕΕΣ"
                    />
                  </button>
                </Anchor> */}
              </div>
            )}
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MenuProducts;
