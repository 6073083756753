import React, { useContext } from "react";
import Anchor from "../elements/Anchor";
import MenuContext from "../../context/MenuContext";

const MenuServices = ({ sevicesMenuItems }) => {
  const { closeMenu } = useContext(MenuContext);

  return (
    <div className="menu-services-container d-flex flex-column align-items-start">
      {sevicesMenuItems.map((serviceCategory, i) => {
        return (
          <div
            key={serviceCategory.id}
            className={`mb-5 serviceCategoryNo${i + 1}`}
          >
            <h3 className="text-primary mb-4">
              {serviceCategory.primary.service_category_label}
            </h3>
            <div>
              {serviceCategory.items.map((service) => {
                return (
                  <button
                    type="button"
                    key={service.label}
                    onClick={() => closeMenu()}
                  >
                    <Anchor href={`${service.link.url}` || "/"}>
                      <h4 className={`mb-3 ${service.stand_out_red && "text-primary"}`}>
                        {service.label}
                      </h4>
                    </Anchor>
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MenuServices;
