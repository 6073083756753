import React, { useContext } from "react";
import { Offcanvas } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";
import MenuProductCategoryList from "../blocks/MenuProductCategoryList";
import MenuServices from "../blocks/MenuServices";
import MenuProducts from "../blocks/MenuProducts";
import MenuContext from "../../context/MenuContext";

const Menu = ({ menuItems }) => {
  const {
    isMenuOpen,
    setIsMenuOpen,
    isProductCategoryListOpen,
    setIsProductCategoryListOpen,
    // isProductCategoryOpen,
    setIsProductCategoryOpen,
    isServicesOpen,
    setIsServicesOpen,
    activeCategoryId,
    setActiveCategoryId,
    innerWidth,
    closeMenu,
  } = useContext(MenuContext);

  const genericMenuItems = menuItems.filter(
    (item) => item.slice_type === "generic_menu_item"
  )[0].items;

  const productCategoryList = menuItems.filter(
    (item) => item.slice_type === "menu"
  );

  const sevicesMenuItems = menuItems.filter(
    (item) => item.slice_type === "service_menu_item"
  );

  let activeCategoryData = null;
  if (activeCategoryId && productCategoryList) {
    // eslint-disable-next-line prefer-destructuring
    activeCategoryData = productCategoryList.filter(
      (productCategory) => productCategory.id === activeCategoryId
    )[0];
  }

  let activeCategoryProducts = null;
  if (activeCategoryId && productCategoryList) {
    activeCategoryProducts = activeCategoryData.items.map((product) => {
      return (
        <button
          type="button"
          key={product.sub_label}
          onClick={() => closeMenu()}
        >
          <Anchor href={`${product.sub_link.url ? product.sub_link.url : ""}`}>
            <h4 className="mb-4">{product.sub_label}</h4>
          </Anchor>
        </button>
      );
    });
  }

  // REMOVED IT BECAUSE OF MULTIPLE RERENDERS
  // useEffect(() => {
  //   if (typeof window !== `undefined`) {
  //     window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
  //     return () =>
  //       window.removeEventListener("resize", () =>
  //         setInnerWidth(window.innerWidth)
  //       );
  //   }
  // }, []);

  return (
    <>
      {/* MOBILE AND DESKTOP MENU LVL 1 */}
      <Offcanvas
        show={isMenuOpen}
        onHide={() => setIsMenuOpen(false)}
        placement="start"
        backdrop="false"
        className="menu-container"
      >
        <Offcanvas.Body>
          <div className="menuLvl1 d-flex flex-column justify-content-between flex-lg-row justify-content-lg-start">
            <div className="d-flex flex-1 justify-content-end close-menu-btn">
              <button className="p-4" type="button" onClick={() => closeMenu()}>
                <StaticImage
                  src="../../images/icons/close-btn.png"
                  alt="close button"
                />
              </button>
            </div>
            <button
              type="button"
              className="ps-5 d-lg-none"
              onClick={() => setIsProductCategoryListOpen(true)}
            >
              <h1 className="d-flex text-dark">Προϊόντα</h1>
            </button>
            <button
              type="button"
              className="ps-5 d-lg-none"
              onClick={() => setIsServicesOpen(true)}
            >
              <h1 className="d-flex text-dark">Υπηρεσίες</h1>
            </button>
            <div className="menu-generic-wrapper p-5 bg-dark text-light ">
              <h1 className="d-none d-lg-block my-5">Γενικά</h1>
              <div className="d-flex flex-column align-items-start">
                {genericMenuItems.map((item) => {
                  return (
                    <button
                      type="button"
                      key={item.label}
                      onClick={() => closeMenu()}
                      className="pt-2 d-flex text-light"
                    >
                      <Anchor
                        className="text-light"
                        href={item.internal_hash_link || item.link.url}
                      >
                        {item.label}
                      </Anchor>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="menu-product-wrapper scroll-no-scrollbar d-none d-lg-flex flex-column p-5 bg-light">
              <h1 className="py-5 text-dark">Προϊόντα</h1>
              <MenuProductCategoryList
                productCategoryList={productCategoryList}
              />
            </div>
            <div className="menu-services-wrapper scroll-no-scrollbar d-none d-lg-flex flex-column flex-grow-1 p-5">
              <h1 className="py-5 text-dark">Υπηρεσίες</h1>
              <MenuServices sevicesMenuItems={sevicesMenuItems} />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* PRODUCT CATEGORIES MOBILE MENU LVL 2 */}
      <Offcanvas
        show={isProductCategoryListOpen}
        onHide={() => setIsProductCategoryListOpen(false)}
        placement="start"
        backdrop="false"
        className="menu-container bg-light"
      >
        <Offcanvas.Body>
          <div className="d-flex flex-1 justify-content-between">
            <button
              className="p-4"
              type="button"
              onClick={() => setIsProductCategoryListOpen(false)}
            >
              <StaticImage
                src="../../images/icons/back-btn.png"
                alt="back button"
              />
            </button>
            <button className="p-4" type="button" onClick={() => closeMenu()}>
              <StaticImage
                src="../../images/icons/close-btn.png"
                alt="close button"
              />
            </button>
          </div>
          <div className="px-5 py-4">
            <MenuProductCategoryList
              productCategoryList={productCategoryList}
              setActiveCategoryId={setActiveCategoryId}
              setIsProductCategoryOpen={setIsProductCategoryOpen}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* FINAL PRODUCTS MOBILE MENU LVL 3 */}
      <MenuProducts
        activeCategoryProducts={activeCategoryProducts}
        activeCategoryData={activeCategoryData}
        placement={innerWidth < 992 ? "start" : "end"}
      />
      {/* SERVICES MOBILE MENU LVL 2 */}
      <Offcanvas
        show={isServicesOpen}
        onHide={() => setIsServicesOpen(false)}
        placement="start"
        backdrop="false"
        className="menu-container"
      >
        <Offcanvas.Body>
          <div className="menu-product-list d-flex flex-column">
            <div className="d-flex flex-1 justify-content-between">
              <button
                className="p-4"
                type="button"
                onClick={() => setIsServicesOpen(false)}
              >

                <StaticImage
                  src="../../images/icons/back-btn.png"
                  alt="back button"
                />
              </button>
              <button className="p-4" type="button" onClick={() => closeMenu()}>
                <StaticImage
                  src="../../images/icons/close-btn.png"
                  alt="close button"
                />
              </button>
            </div>
            <div className="px-5 py-4">
              <MenuServices
                sevicesMenuItems={sevicesMenuItems}
                closeMenu={closeMenu}
              />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Menu;
