import React, { createContext, useState } from "react";
import { isBrowser } from "../utilities";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductCategoryListOpen, setIsProductCategoryListOpen] =
    useState(false);
  const [isProductCategoryOpen, setIsProductCategoryOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [activeCategoryId, setActiveCategoryId] = useState(undefined);
  const [innerWidth] = useState(isBrowser ? window.innerWidth : null);
  const [stores, setStores] = useState([]);
  const closeMenu = () => {
    setIsProductCategoryOpen(false);
    setIsProductCategoryListOpen(false);
    setIsMenuOpen(false);
    setIsServicesOpen(false);
  };

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        setIsMenuOpen,
        isProductCategoryListOpen,
        setIsProductCategoryListOpen,
        isProductCategoryOpen,
        setIsProductCategoryOpen,
        isServicesOpen,
        setIsServicesOpen,
        activeCategoryId,
        setActiveCategoryId,
        innerWidth,
        stores,
        setStores,
        closeMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContext;
