import React, { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import MenuContext from "../../context/MenuContext";

const MenuProductCategoryList = ({ productCategoryList }) => {
  const { setActiveCategoryId, setIsProductCategoryOpen } =
    useContext(MenuContext);

  return (
    <div className="d-flex flex-column align-items-start">
      {productCategoryList.map((category) => {
        return (
          <button
            key={category.id}
            type="button"
            className="category-menu-item"
            onClick={() => {
              setActiveCategoryId(category.id);
              setIsProductCategoryOpen(true);
            }}
          >
            <h4 className="d-flex justify-content-between align-items-center">
              {category.primary.label}
              <StaticImage
                src="../../images/icons/redRightArrowNoBG.png"
                alt="red right arrow"
                className="category-menu-item-arrow"
              />
            </h4>
          </button>
        );
      })}
    </div>
  );
};

export default MenuProductCategoryList;
